import React, { useState } from 'react'
import { useForm } from "../hooks/useForm";
import axios from "axios";
import "../assets/css/form.css"
import "../assets/css/radio.css"
import "../assets/css/dropdown.css"

const Form = () => {

    const utms = window.location.search.substring(1).split("&");
    const utm_source = utms[0] ? utms[0].split("=").pop() : "Organic";
    const utm_medium = utms[1] ? utms[1].split("=").pop() : "Organic";
    const utm_campaign = utms[2] ? utms[2].split("=").pop() : "Organic";
    const utm_term = utms[3] ? utms[3].split("=").pop() : "Organic";
    const utm_content = utms[4] ? utms[4].split("=").pop() : "Organic";

    const initialForm = {
      type_propriety:"",
      has_service:"",
      theft:"",
      houses_access:"",
      houses_levels:"",
      size_propriety:"",
      nombre:"",
      telefono:"",
      email:"",
      provincia:"",
    };

    const [currentStep, setCurrentStep] = useState(0);
    const [emptyError, setEmptyError] = useState({});
    const [formValues, handleInputChange, reset] = useForm(initialForm);


    const handleSelectNextClick = async (event) => {
      //setSelections({...selections, [event.target.name]:event.target.value})
      handleInputChange(event);
      setCurrentStep((prevStep) => prevStep + 1);
    };

    const validar_email = (email) => {
      return /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(email)
    }

    const validar_tel = (tel) => {
      return tel.length > 8 && tel.length < 13
    }

    const handleSubmitClick = async (event) => {
        event.preventDefault();
        var err = {}
        for (let k in formValues){
          err = {...err, [k]:(formValues[k]==="")};
        }
        err["email"]=err["email"]||!validar_email(formValues.email)
        err["telefono"]=err["telefono"]||!validar_tel(formValues.telefono)

        setEmptyError(err)
        //console.log(formValues)

        if (Object.values(err).includes(true)){
          return;
        }else {


          let lead = {
            ...formValues,
            utm_medium_c: utm_medium,    
            utm_source_c: utm_source,
            utm_content_c: utm_content,
            utm_campaign_c: utm_campaign,
            utm_term_c: utm_term,
            status: "New",
            assigned_user_id: "821db49c-70f4-8040-d6f9-62ff989b79b5",
          }

          await axios({
            method: "POST",
            url: "processor.php",
            headers: {
              "Content-Type": "application/json",
            },
            data: lead,
          })
            .then((res) => {
              if (res.data.includes("success")) {
                //setCorrectValidation(1); // Seteo la variable en 1 para que en la pagina se muestre el mensaje de "Uno de nuestros representantes lo contactará a la brevedad."
                setCurrentStep((prevStep) => prevStep + 1);
                reset();
                //setLoading(false);
              } else {
                alert("Ha ocurrido un error inesperado!")
                //setCorrectValidation(5);
                //setLoading(false);
              }
            })
            .catch((err) => {
              console.warn(err);
                //setCorrectValidation(5); // "A ocurrido un error inesperado"
                alert("Ha ocurrido un error inesperado")
                //setLoading(false);
            });

          setTimeout(() => {
            setEmptyError({})
            //setCorrectValidation(0);
          }, 4000);
          return;
        }

    };
  return (
    <div className="row">
      <div className="col-md-8 col-md-offset-3">
        <form id="msform">
          <div style={{ display: currentStep < 6 ? 'block' : 'none' }}>
            <h1>Prosegur garantiza la protección de tu hogar.</h1>
            <ul id="progressbar">
              <li className={currentStep === 0 || currentStep === 1 || currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 ? 'active' : ''}></li>
              <li className={currentStep === 1 || currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 ? 'active' : ''}></li>
              <li className={currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 ? 'active' : ''}></li>
              <li className={currentStep === 3 || currentStep === 4 || currentStep === 5 ? 'active' : ''}></li>
              <li className={currentStep === 4 || currentStep === 5 ? 'active' : ''}></li>
              <li className={currentStep === 5 ? 'active' : ''}></li>
            </ul>
          </div>
          {/* 1. Tipo de propiedad */}
          <fieldset style={{ display: currentStep === 0 ? 'block' : 'none' }}>
            <h2 className="fs-title">1. Propiedad</h2>
            <h3 className="fs-subtitle">¿Para que tipo de propiedad es?</h3>

            <div className='selection-container-type-property'>

              <label className="selection_radio" htmlFor="1_hogar">Hogar
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_hogar" name="type_propriety" value="hogar"/>
                <span className="checkmark"></span>
              </label>
              <span/>
              <label className="selection_radio" htmlFor="1_negocio">Negocio
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="1_negocio" name="type_propriety" value="negocio"/>
                <span className="checkmark"></span>
              </label>

            </div>

          </fieldset>

          {/* 2. Servicio actual */}
          <fieldset style={{ display: currentStep === 1 ? 'block' : 'none' }}>
            <h2 className="fs-title">2. Alarma</h2>
            <h3 className="fs-subtitle">¿Actualmente cuentas con un sistema de alarma?</h3>

            <div className='selection-container-type-property'>

              <label className="selection_radio" htmlFor="2_no">No, no tengo
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="2_no" name="has_service" value="no_tiene_servicio"/>
                <span className="checkmark"></span>
              </label>
              <span/>
              <label className="selection_radio" htmlFor="2_si">Sí, si tengo
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="2_si" name="has_service" value="si_tiene_servicio"/>
                <span className="checkmark"></span>
              </label>

            </div>

          </fieldset>

          {/* 3. Han robado en la vivienda? */}
          <fieldset style={{ display: currentStep === 2 ? 'block' : 'none' }}>
            <h2 className="fs-title">3. Seguridad</h2>
            <h3 className="fs-subtitle">¿Han robado alguna vez en tu propiedad?</h3>

            <div className='selection-container-type-property'>

              <label className="selection_radio" htmlFor="3_si_robado">Sí, han robado
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="3_si_robado" name="theft" value="si_robado"/>
                <span className="checkmark"></span>
              </label>
              <span/>
              <label className="selection_radio" htmlFor="3_no_robado">No, no han robado
                <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="3_no_robado" name="theft" value="no_robado"/>
                <span className="checkmark"></span>
              </label>

            </div>

          </fieldset>

          {/* 4. Cantidad de accesos del inmueble */}
          <fieldset style={{ display: currentStep === 3 ? 'block' : 'none' }}>
            <h2 className="fs-title">4. Acceso</h2>
              <h3 className="fs-subtitle">¿Cuántos accesos hay en tu inmueble desde el exterior?</h3>

              <div className='selection-container'>

                <label className="selection_radio" htmlFor="4_1_acceso">1 acceso
                  <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="4_1_acceso" name="houses_access" value="1_acceso"/>
                  <span className="checkmark"></span>
                </label>

                <label className="selection_radio" htmlFor="4_2_accesos">2 accesos
                  <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="4_2_accesos" name="houses_access" value="2_accesos"/>
                  <span className="checkmark"></span>
                </label>

                <label className="selection_radio" htmlFor="4_mas_3_accesos">Más de 3 accesos
                  <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="4_mas_3_accesos" name="houses_access" value="mas_3_accesos"/>
                  <span className="checkmark"></span>
                </label>

              </div>

          </fieldset>

          {/* 5. Cuantos pisos tiene la vivienda? */}
          <fieldset style={{ display: currentStep === 4 ? 'block' : 'none' }}>
            <h2 className="fs-title">5.Pisos</h2>
              <h3 className="fs-subtitle">¿Cuántos pisos tiene tu inmueble?</h3>

              <div className='selection-container'>

                <label className="selection_radio" htmlFor="5_1_piso">1 piso
                  <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="5_1_piso" name="houses_levels" value="1_piso"/>
                  <span className="checkmark"></span>
                </label>

                <label className="selection_radio" htmlFor="5_2_pisos">2 pisos
                  <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="5_2_pisos" name="houses_levels" value="2_pisos"/>
                  <span className="checkmark"></span>
                </label>

                <label className="selection_radio" htmlFor="5_mas_3_pisos">Más de 3 pisos
                  <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="5_mas_3_pisos" name="houses_levels" value="mas_3_pisos"/>
                  <span className="checkmark"></span>
                </label>

              </div>

          </fieldset>

          {/* 6. Metros de superficie */}
          <fieldset style={{ display: currentStep === 5 ? 'block' : 'none' }}>
            <h2 className="fs-title">6.Superficie</h2>
              <h3 className="fs-subtitle">¿Cuántos metros tiene aproximadamente?</h3>

              <div className='selection-container'>

                <label className="selection_radio" htmlFor="6_0_70">Menos de 70 mts2
                  <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="6_0_70" name="size_propriety" value="de_0_a_70m2"/>
                  <span className="checkmark"></span>
                </label>

                <label className="selection_radio" htmlFor="6_71_100">Entre 71 mts2 y 100 mts2
                  <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="6_71_100" name="size_propriety" value="de_71_a_100m2"/>
                  <span className="checkmark"></span>
                </label>

                <label className="selection_radio" htmlFor="6_mas_100">Más de 101 mts 2
                  <input onClick={handleSelectNextClick} className='radio_button' type="radio" id="6_mas_100" name="size_propriety" value="mas_de_100m2"/>
                  <span className="checkmark"></span>
                </label>

              </div>

          </fieldset>

          {/* 7. Datos personales */}
          <fieldset style={{ display: currentStep === 6 ? 'block' : 'none' }}>
          <h2 className="fs-title-end">Cotización personalizada casi finalizada</h2>
            <div className='text-input-container'>

              <input
                required={true}
                className={emptyError.nombre?"error-text":''}
                type="text"
                name="nombre"
                placeholder={emptyError.nombre?"Llene el campo nombre":"Nombre"}
                value={formValues.nombre}
                onChange={handleInputChange}
                onBlur={() => setEmptyError({...emptyError, nombre:formValues.nombre===""})}
                onFocus={() => setEmptyError({...emptyError, nombre:false})}
                onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
              />

              <input
                required={true}
                className={emptyError.telefono?"error-text":''}
                type="number"
                name="telefono"
                placeholder={emptyError.telefono?"Llene el campo teléfono":"Teléfono"}
                value={formValues.telefono}
                onChange={handleInputChange}
                onBlur={() => setEmptyError({...emptyError, telefono:formValues.telefono===""})}
                onFocus={() => setEmptyError({...emptyError, telefono:false})}
                onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
              />

              <input
                required={true}
                className={emptyError.email?"error-text":''}
                type="email"
                name="email"
                placeholder={emptyError.email?"Llene el campo email":"Email"}
                value={formValues.email}
                onChange={handleInputChange}
                onBlur={() => setEmptyError({...emptyError, email:(formValues.email==="")})}
                onFocus={() => setEmptyError({...emptyError, email:false})}
                onKeyDown={(e) => {if (e.key === "Enter") handleSubmitClick(e)}}
              />

              <div className='dropdown-container'>
                <select
                  name="provincia"
                  className={emptyError.provincia?"error-text":''}
                  value={formValues.provincia}
                  onChange={handleInputChange}
                  onBlur={() => setEmptyError({...emptyError, nombre:formValues.provincia===""})}
                  onFocus={() => setEmptyError({...emptyError, nombre:false})}
                  required
                  style={{
                    color: formValues.provincia === "" ? "#8f98a1" : "#000"
                  }}
                >
                  <option value="" disabled hidden>Seleccionar departamento</option>
                  <option value="lima">Lima</option>
                  <option value="amazonas">Amazonas</option>
                  <option value="ancash">Ancash</option>
                  <option value="apurimac">Apurimac</option>
                  <option value="arequipa">Arequipa</option>
                  <option value="ayacucho">Ayacucho</option>
                  <option value="cajamarca">Cajamarca</option>
                  <option value="callao">Callao</option>
                  <option value="cusco">Cusco</option>
                  <option value="huancavelica">Huancavelica</option>
                  <option value="huanuco">Huanuco</option>
                  <option value="ica">Ica</option>
                  <option value="junin">Junín</option>
                  <option value="la_libertad">La Libertad</option>
                  <option value="lambayeque">Lambayeque</option>
                  <option value="loreto">Loreto</option>
                  <option value="madre_de_dios">Madre de Dios</option>
                  <option value="moquegua">Moquegua</option>
                  <option value="pasco">Pasco</option>
                  <option value="piura">Piura</option>
                  <option value="puno">Puno</option>
                  <option value="san_martin">San Martín</option>
                  <option value="tacna">Tacna</option>
                  <option value="tumbes">Tumbes</option>
                  <option value="ucayali">Ucayali</option>
                </select>
                <br/>

              </div>
              
            <div className="final-buttons">
              <button className="submit action-button" onClick={handleSubmitClick}>Enviar</button>
            </div>

            </div>

          </fieldset>

          <div style={{ display: currentStep === 7 ? 'block' : 'none' }}>
            <h4>¡MUCHAS GRACIAS!</h4>
            <h5>HEMOS RECIBIDO CORRECTAMENTE TU SOLICITUD</h5>
            <h6>En breve un asesor se contactará contigo.</h6>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form